<template>
    <CommonHeader />
    <!--*************alert red-->
    <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{ alertMessage }}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
    </div>
    <!--*************alert red-->
    <!--*************alert green-->
    <div :class="alertClassg" id="hidemessageg" v-if="messageg">
        <p class="txt">{{ alertMessageg }}</p>
        <i class="fa fa-times close_msgg" aria-hidden="true" @click="close_msgg()"></i>
    </div>
    <!--*************alert green-->
    <div id="main-content">
        <div id="page-container">
            <br>
            <h4 class="hedd" style="color: #f97d40;font-size: 25px;font-weight:500">GSTR-2A ITC RECONCILIATION REPORT
            </h4>
            <br>
            <p style="font-size: 16px;font-family: system-ui;">
                This Excel report allows you to reconcile your purchases entered in Instabill with your
                supplier's invoices (GSTR2A) uploaded on the GST portal.
                We 'll automatically highlight the mismatches for you to review and take the right action.
            </p>
            <div class="top_bar">
                <form v-on:submit.prevent="report_form">
                    <select v-model="reportMonth">
                        <option value="">Select Month</option>
                        <option value="01">01 January</option>
                        <option value="02">02 February</option>
                        <option value="03">03 March</option>
                        <option value="04">04 April</option>
                        <option value="05">05 May</option>
                        <option value="06">06 June</option>
                        <option value="07">07 July</option>
                        <option value="08">08 August</option>
                        <option value="09">09 September</option>
                        <option value="10">10 October</option>
                        <option value="11">11 November</option>
                        <option value="12">12 December</option>
                    </select>
                    <select v-model="reportYear">
                        <option value="">Select Year</option>
                        <option value="2026">2026</option>
                         <option value="2025">2025</option>
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                    </select>
                    <button type="submit" class="dt_submit">Generate Report</button>
                </form>
            </div>
            <table class="list-box">
                <tr class="list">
                    <!-- <th>File Name</th> -->
                    <th>Upload Date</th>
                    <th>Status</th>
                    <th>Download</th>
                </tr>
                <tr v-for="(item, index) in getListItems" :key="index">
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <button @click="downloadReport(item.gstin, item.return_period, item.id)"
                            :disabled="loadingStates[item.gstin]" class="dt_submit px-3 py-2">
                            <span v-if="loadingStates[item.id]">Loading...</span>
                            <span v-else>Download</span>
                        </button>
                    </td>
                </tr>

            </table>
        </div>
    </div>
    <!-- sidebar -->
    <div v-if="gstModal" class="gst_details">
        <div v-if="gstDetailSection">
            <h3>Add GST details</h3>
            <span role="button" aria-hidden="" class="closed_upload_doc" @click="closeModelUpload">
                <i class="fa fa-times" aria-hidden="true"></i></span>
            <p style="font-size: 12px;font-family: sans-serif;margin-top: 10px;">
                Enter your GST credentials for Instabill to fetch details from the GST portal.
            </p>
            <form v-on:submit.prevent="checkGstNSubmit">
                <div class="in_p">
                    <label>GSTIN*</label>
                    <input v-model="gstin" type="text" maxlength="15" required="">
                </div>
                <div class="in_p">
                    <label>Username*</label>
                    <input v-model="gstUsername" type="text" required="">
                </div>
                <button type="submit">Get OTP</button>
            </form>
        </div>
        <div v-if="gstOtpSection">
            <form v-on:submit.prevent="validateGstOtp">
                <div class="otp_Section">
                    <p style="font-size: 12px;font-family: sans-serif;margin-top: 10px;">Enter the One Time Password
                        (OTP) Received on your mobile number registered with the GST portal.</p>
                    <div class="in_p">
                        <label>OTP*</label>
                        <input type="text" v-model="userOtp" maxlength="6" placeholder="Enter OTP" required="">
                    </div>
                    <u role="button" @click="checkGstNSubmit"
                        style="font-size: 12px;font-family: sans-serif;margin-top: 10px;">Resend OTP</u>
                    <div class="ft">
                        <button @click="closeModelUpload" class="btna1"
                            style="background: #eee;border: 1px solid #ffffff;color: #000;">Cancel</button>
                        <button type="submit" class="btna1">Verify OTP</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import CommonHeader from './CommonHeader.vue';
import axios from 'axios';
import { postApi } from "../assets/js/helper.js";
export default {
    name: 'Gstr2a',
    components: {
        CommonHeader
    },
    data() {
        return {
            alertClass: "",
            message: false,
            alertClassg: "",
            messageg: false,
            gstModal: false,
            gstDetailSection: true,
            gstOtpSection: false,
            reportMonth: "",
            reportYear: "",
            gstin: "",
            userOtp: "",
            gstUsername: "",
            userId: null,
            companyId: null,
            getListItems: [],
            loadingStates: [],
            inputGstNumber: '',
            inputGstUsername: '',
        };
    },
    created() {
        this.userId = this.$route.params.user_id;
        this.companyId = this.$route.params.company_id;
        this.getList();
    },
    methods: {
        close_msg() {
            this.message = false;
        }, close_msgg() {
            this.messageg = false;
        }, greenAlert(messageg) {
            this.alertMessageg = messageg;
            this.alertClassg = "error_msgg";
            this.messageg = true;
            setTimeout(() => this.messageg = '', 3000);
            // this.message = '';
        }, redAlert(message) {
            this.alertMessage = message;
            this.alertClass = "error_msg";
            this.message = true;
            setTimeout(() => this.message = '', 3000);
            // this.message = '';
        }, report_form() {
            if (!this.reportMonth) {
                this.redAlert('Please Select Month !');
                return false;
            }
            if (!this.reportYear) {
                this.redAlert('Please Select Year !');
                return false;
            }
            // let formData = new FormData();
            // formData.append('reportMonth', this.reportMonth);
            // formData.append('reportYear', this.reportYear);
            // console.log(this.reportMonth);
            // console.log(this.reportYear);
            this.showGstModal();
        }, showGstModal() {
            this.gstModal = true;
            this.gstDetailSection = true;
        }, closeModelUpload() {
            this.gstModal = false;
            this.gstDetailSection = false;
            this.gstOtpSection = false;
        }, checkGstNSubmit() {
            if (!this.gstin) {
                this.redAlert('Please Mention Gst Number !');
                return false;
            } else if (this.gstin.length != 15) {
                this.redAlert('Please Mention Valid Gst Number !');
                return false;
            }
            if (!this.gstUsername) {
                this.redAlert('Please Mention Username !');
                return false;
            }
            let formData = new FormData();
            formData.append('gstin', this.gstin);
            formData.append('username', this.gstUsername);
            axios.post("https://instabill.co/api/send_otp_gstr2a", formData, {
            }).then((response) => {
                // console.log(response);  
                if (response.data.errorCode == "0") {
                    this.greenAlert('Otp Send Successfully !');
                    setTimeout(() => this.message = false, 3000);
                    this.gstDetailSection = false;
                    this.gstOtpSection = true;
                } else {
                    this.redAlert(response.data.errorMsg);
                    setTimeout(() => this.messageg = false, 3000);
                }
            }).catch((error) => {
                alert(error);
            });
        },
         validateGstOtp() {
            if (!this.userOtp) {
                this.redAlert('Please Enter Otp!');
                return false;
            } else if (this.userOtp.length != 6) {
                this.redAlert('Invalid Otp !');
                return false;
            }
            let formData = new FormData();
            formData.append('userId', this.userId);
            formData.append('company_id', this.companyId);
            formData.append('otp', this.userOtp);
            formData.append('month', this.reportMonth);
            formData.append('fy', this.reportYear);
            formData.append('gstin', this.gstin);
            formData.append('username', this.gstUsername);
            axios.post("https://instabill.co/api/verify_otp_gstr2a", formData, {
            }).then((response) => {
                console.log(response);
                if (response.data.errorCode == "0") {
                    this.greenAlert('Gst Validate Successful');
                    setTimeout(() => this.message = false, 3000);
                    this.gstModal = false;
                    this.gstDetailSection = false;
                    this.gstOtpSection = false;
                    this.getList();
                } else {
                    this.redAlert('Something Went Wrong !');
                    setTimeout(() => this.messageg = false, 3000);
                }
            }).catch((error) => {
                alert(error);
            });
        }, 
        async getList() {
            const response = await postApi(
                "/get_data_gstr2a",
                {
                    userId: this.userId,
                    company_id: this.companyId,
                }
            );
            try {
                if (response.errorCode == "0") {
                    console.log(response);
                    this.getListItems = response.data.gstr2a_data;
                    this.inputGstNumber = response.data.gstin;
                    this.inputGstUsername = response.data.gstin_username;
                    this.gstin = this.inputGstNumber;
                    this.gstUsername = this.inputGstUsername;
                }
            } catch (err) {
                alert(err);
            }
        }, 
        downloadReport(gstin, ret_period, id) {
            this.loadingStates[id] = true;
            const url = `https://instabill.co/api/gstr2a_report_download/${this.userId}/${this.companyId}/${ret_period}/${gstin}`;
            axios.get(url, {
                responseType: 'blob'
            }).then((response) => {
                this.loadingStates[id] = false;
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = `GSTR2A_Report_${gstin}_${ret_period}.xlsx`; // Adjust file name as needed
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileUrl);
            })
            .catch((error) => {
                this.loadingStates[id] = false;
                console.error("Error downloading file:", error);
                alert("Failed to download the report. Please try again.");
            });
        }
    }
}
</script>
<style>
.gst_details {
    float: right;
    z-index: 99;
    position: fixed;
    right: 0;
    top: 0;
    background: white;
    height: 100%;
    transition: 1s;
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    width: 350px;
    padding: 15px;
    overflow-y: scroll;
}

.gst_details h3 {
    color: #f97d40;
    font-size: 23px !important;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 35px;
    font-weight: 600 !important;
    letter-spacing: 0px;
}

.gst_details span.closed_upload_doc {
    position: absolute;
    right: 5%;
    top: 19px;
    font-size: 18px;
}

.in_p input {
    font-size: 1rem;
    border: 1px solid #ccc !important;
    font-family: inherit;
    cursor: text;
    color: #737272 !important;
    padding: 2% 3% !important;
    border-radius: 5px !important;
    width: 100% !important;
    height: 40px;
    font-size: 15px;
}

.in_p {
    margin-bottom: 10px;
}

.in_p label {
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.ft {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button.dt_submit {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    border: 0px;
    color: #fff;
    padding: 10px 1%;
    border-radius: 5px;
    margin-top: 0%;
    margin-left: 2%;
    width: auto;
    font-size: 15px;
    text-transform: capitalize;
    margin-left: 14px !important;
}

.gst_details button {
    padding: 8px 14px;
    font-size: 14px;
    width: auto;
    margin-top: 19px;
}

.top_bar select {
    width: 13% !important;
    float: left;
    color: #000 !important;
    margin-right: 16px;
    height: 40px;
    padding: 0px 11px !important;
}

button.btna1 {
    background: linear-gradient(85deg, #f66a59, #ffae00);
}
</style>